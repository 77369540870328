<template>
  <div class="page" style="max-width: 600px; margin: 0 auto;">
    <div style=" height: 100%; background-color: #99d6ff;">
      <div style="position: relative;">
        <img :src="'/img/wjbghd1.jpg'" style="width: 100%; vertical-align: top;" />
        <div style="position: absolute; top: 10vw;">

          <div style="width:70%;margin:0 auto;">
            <img v-if="planInfo" :src="planInfo.config.kvalue" style="width:100%" />
          </div>
        </div>
      </div>

      <div class="boxbg" style="min-height: 60vh;" :style="{ backgroundImage: 'url(/img/wjbghd2.jpg)' }">

        <div style="width: 80%; margin: 0 auto;" v-if="planInfo">
          <img :src="'/img/wjsy_01.png'" style="width: 100%; vertical-align: bottom;" />
          <div class="boxbg" :style="{ backgroundImage: 'url(/img/wjsy_03.png)' }">
            <div style="padding:0 20px;word-break: break-all;font-size: 20px; color: dodgerblue;">
              {{ planInfo.plan_name }}
            </div>
          </div>
          <img :src="'/img/wjsy_05.png'" style="width: 100%; vertical-align: top;" />
          <div class="boxbg" :style="{ backgroundImage: 'url(/img/wjsy_07.png)' }">
            <div class="ivst_desc" style="padding:0 20px;word-break: break-all;" v-html="planInfo.ivst_desc">

            </div>
          </div>
          <img :src="'/img/wjsy_09.jpg'" style="width: 100%; vertical-align: top;" />
        </div>


        <div
          style="position: relative;width: 50%; vertical-align: top; margin: 0 auto; padding-top:30px;box-sizing: content-box;">
          <img :src="'/img/btnsave.png'" style="width: 100%; " />
          <div style="position: absolute; width: 100%; height: 100%; z-index: 1; top: 0; ">
            <!-- <a :href="urlScheme" class="btna"></a> -->
            <a @click="openForm" class="btna"></a>

          </div>
        </div>

      </div>

      <div style="position: relative;">
        <img :src="'/img/wjbghd3.jpg'" style="width: 100%; vertical-align: top;" />
        <div style="position: absolute; top: 0;">
        </div>
      </div>





    </div>


    <div v-if="showUser" style="position: absolute; width: 100%; height: 100%; top: 0; background: rgba(0,0,0,0.5);">
      <div style="height: 30vw;">

      </div>
      <div style="width: 70%; margin: 0 auto;">
        <img src="../../../public/img/wj_wsxx_copy.png" style="width: 100%; vertical-align: middle;" />
        <div class="flexCenter" style="background-color:#fff;padding: 10px 0;">
          <span style="width:80%;margin:0 auto; color:#33a9ff;">请选择班级、填写姓名</span>
        </div>
      </div>
      <div
        style="width: 70%; margin: 0 auto; background-color: #fff; border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">


        <div style="border-bottom: 1px solid #f6f6f6; width: 80%; margin: 0 auto; padding: 10px 0;">
          <span class="required" style="font-size: 14px;">班级：</span>
          <div style="float: right; position: relative; width: 70%; text-align: right;">

            <div>
              <input type="text" :value="userMore.class_name"
                style="width: 100%; outline: none; border: 0; text-align: right; float: right;" placeholder="请选择班级" />
            </div>
            <div style="position: absolute; top: 0; width: 100%; height: 100%;">
              <select v-model="userMore.class_id" style="opacity: 0; width: 100%;" @change="changeSelect">
                <option v-for="(y, i) in ClassList" :key="i" :value="y.id">{{ y.class_name }}
                </option>

              </select>
            </div>
          </div>
        </div>

        <div style="border-bottom: 1px solid #f6f6f6; width: 80%; margin: 0 auto; padding: 16px 0;">
          <span class="required" style="font-size: 14px;">姓名：</span>
          <input type="text" v-model="userMore.stu_name"
            style="width: 50%; outline: none; border: 0; text-align: right; float: right;" placeholder="请输入姓名" />
        </div>

        <div style="margin: 30px auto; width: 60%; padding-bottom: 30px;" @click="confirmChoujiang">
          <img src="../../../public/img/wj02_btn.png" style="width: 100%;" />
        </div>
      </div>



    </div>

    <div id="toast" class="toast">
      请选择完整
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      urlScheme: "",
      planInfo: null,
      showUser: false,
      userMore: {
        id: ''
      },
      ClassList: [],
      stu_id: '',
      target_classes:'',
			choosedClasses: 0,
    }
  },
  mounted() {
    this.getSchool()

  },
  methods: {
    getSchool() {
      this.$http.post("/api/ivst_welcome", { plan_id: this.$route.params.id }).then(res => {
        this.planInfo = res.data
        window.document.title = res.data.ivst_title
        //生成urlScheme
        if (this.planInfo.count == 1) {
          this.$http.post("/api/ivst_generatescheme", { pmid: this.planInfo.pmid, plan_id: this.$route.params.id }).then(ok => {
            if (ok.data.openlink) {
              this.urlScheme = ok.data.openlink
            }
          })
        } else {

        }
        if (this.planInfo.pmid==209) {
          this.getClasses()
        } else {
          this.get518Classes()
        }
      })
    },
    get518Classes() {
      console.log()
      this.$http.post("/api/h5_518session_class", {
        pmid: this.planInfo.pmid
      }).then(res => {
        res.data.map(a => {
          a.class_name = a.session_name + '-' + a.class_name
        })
        this.ClassList = res.data
      })
    },
    getClasses() {
      this.$http.post("/api/ivst_plan", { plan_id: this.$route.params.id, edit: 1 }).then(res => {
        let e = res.data
        e.moban_arr = e.temp
        let info = JSON.parse(JSON.stringify(e))
        let sta_end_time = [];
        sta_end_time.push(new Date(e.sta_time));
        sta_end_time.push(new Date(e.end_time));
        info.sta_end_time = sta_end_time
        if (info.moban_arr && info.moban_arr.length > 0) {
          info.moban_arr.map((a, index) => {
            this.target_classes = a.target_classes ? a.target_classes : ""
            this.choosedClasses = 0;
          
            if (this.target_classes.indexOf(",") > 0) {
              this.choosedClasses = this.target_classes.split(",").length - 2
            } else if (this.target_classes > 0) {
              this.choosedClasses = 1
            }
            if (this.choosedClasses > 0) {
              this.$http.post("/api/h5_ivst_checked_class", {
                ids: this.target_classes,
                pmid: this.planInfo.pmid
              }).then(res => {
                res.data.map((a, ia) => {
                  a.class_name = a.session_name + '-' + a.class_name
                })
                this.ClassList = res.data
              })
            }
          })
        } 
      })

    },
    goNext() {

    },
    openForm() {
      this.showUser = true
    },
    changeSelect(e) {
      this.ClassList.map(a => {
        if (a.id == this.userMore.class_id) {
          this.userMore.class_name = a.class_name
        }
      })
    },
    confirmChoujiang() {

     
      if (!this.userMore.class_id) {
        this.showToast("请选择您的班级")
        return
      }
       if (!this.userMore.stu_name.trim()) {
        this.showToast("请输入您的姓名")
        return
      }
      this.userMore.pmid = this.planInfo.pmid
      this.userMore.stu_name = this.userMore.stu_name? this.userMore.stu_name.replace(/\s+/g, "") : ''
      this.$http.post("/api/h5_ivst_check_user", this.userMore).then(res => {
        if (res.data.id) {
          this.stu_id = res.data.id
          sessionStorage.setItem("ivst_stu_id", res.data.id)
          sessionStorage.setItem("ivst_pmid", this.planInfo.pmid)
          this.$router.push(`/h5_ivst_plan?stuid=${res.data.id}&pid=${this.$route.params.id}`)
      this.showUser = false

        } else {
          this.showToast('班级姓名未匹配，请检查~')
        }
      })

    },
    showToast(msg) {
      $("#toast").text(msg).show().animate({
        opacity: 1
      }, 200, "linear", function () {
        setTimeout(() => {
          $("#toast").animate({
            opacity: 0
          }, "linear", 3000, function () {
            $("#toast").hide()
          })
        }, 2000)
      })
    },
  }
}
</script>

<style scoped>
.boxbg {
  background-size: 100% auto;
  background-repeat: repeat;
}

.btna {
  width: 100%;
  height: 100%;
  display: block;
}

.toast {
  position: fixed;
  width: 70%;
  left: 15%;
  text-align: center;
  padding: 5px 0;
  border-radius: 4px;
  bottom: 50%;
  background: orangered;
  color: #FFFFFF;
  font-size: 14px;
  opacity: 0;
  display: none;
}

 .required{
    position: relative;
  }
  .required:before {
    position: absolute;
    content: '*';
    color: #f56c6c;
    margin-left: -8px;
  }

</style>

<style>
.ivst_desc p:nth-child(1) {
  margin-top: 0;
}

.ivst_desc p:last-child {
  margin-bottom: 0;
}
</style>